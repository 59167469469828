import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DashboardWrapper from '../../../../components/DashboardWrapper';
import Loader from 'react-loader-spinner';
import { fetchCompany } from '../../../../redux-store/Actions/company-action';
import { useDispatch, useSelector } from 'react-redux';
import { registerApi } from '../../../../api/auth';
import { toast } from 'react-toastify';
import { translate } from "../../../../components/LanguageTranslation/translate";

const schema = yup
  .object({
    firstName: yup.string().required().min(3).max(255).label('First Name'),
    lastName: yup.string().required().min(3).max(255).label('Last Name'),
    email: yup.string().required().min(3).max(255).label('Email Address'),
    phone: yup.string().required().min(3).max(255).label('Phone Number'),
  })
  .required();

function CreateStaff() {
  var user = JSON.parse(localStorage.getItem('RIFALYPUBLISHER'));
  const dispatch = useDispatch();

  const company = JSON.parse(localStorage.getItem('RIFALYCOMPANY'));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    data.fullName = data.firstName + ' ' + data.lastName;
    data.companyId = company.id;
    let options = {
      authorization: `Bearer ${user.token}`,
    };
    console.log(data);
    registerApi(data, options)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        var path = '/staffs/';
        navigate(path);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error.response.data.error, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    let id = user.companyId;
    dispatch(fetchCompany(id));
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <nav aria-label='breadcrumb mb-2'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage('/dashboard')}>
                      {translate("Dashboard", "Dashibodi")}
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage('/staffs')}>
                      {translate("Staff", "wafanyakazi")}
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    {translate("Create Staff", "Ongeza Mfanyakazi")}
                  </li>
                </ol>
              </nav>
            </div>

            <div className='content'>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div className='card pt-2 mt-2'>
                    <div className='px-4 pt-4'>
                      <div className=''>
                        <h6 className='header-pretitle'>{translate("Overview", "Muhtasari")}</h6>

                        <h1 className='header-title'>{translate("Create Staff", "Ongeza Mfanyakazi")}</h1>
                      </div>

                      <hr />

                      <div className='row'>
                        <div className='col-md-12'>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='firstName'
                                    className='form-label'>
                                    {translate("First Name", "Jina la kwanza")}
                                  </label>
                                  <input
                                    type='text'
                                    className={
                                      errors.firstName
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id='firstName'
                                    placeholder='Enter First name'
                                    autoFocus
                                    required
                                    {...register('firstName')}
                                  />

                                  <p className='errors'>
                                    {errors.firstName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='firstName'
                                    className='form-label'>
                                    {translate("Last Name", "Jina la mwisho")}
                                  </label>
                                  <input
                                    type='text'
                                    className={
                                      errors.lastName
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id='lastName'
                                    placeholder='Enter Last name'
                                    required
                                    {...register('lastName')}
                                  />

                                  <p className='errors'>
                                    {errors.lastName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='company'
                                    className='form-label'>
                                    {translate("Company", "Kampuni")}
                                  </label>
                                  <select
                                    className='form-control'
                                    {...register('companyId')}
                                    value={company.id}>
                                    <option value={company.id}>
                                      {company.companyName}{' '}
                                    </option>
                                  </select>

                                  <p className='errors'>
                                    {errors.companyId?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='firstName'
                                    className='form-label'>
                                    {translate("Email Address", "Barua Pepe")}
                                  </label>
                                  <input
                                    type='email'
                                    className={
                                      errors.email
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id='email'
                                    placeholder='Enter Email Address'
                                    required
                                    {...register('email')}
                                  />

                                  <p className='errors'>
                                    {errors.email?.message}
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='firstName'
                                    className='form-label'>
                                    {translate("Phone Number", "Nambari ya Simu")}
                                  </label>
                                  <input
                                    type='text'
                                    className={
                                      errors.phone
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id='phone'
                                    placeholder='Enter Phone Number'
                                    required
                                    {...register('phone')}
                                  />

                                  <p className='errors'>
                                    {errors.phone?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='role'
                                    className='form-label'>
                                    {translate("Select Role", "Chagua Jukumu")}
                                  </label>
                                  <select
                                    className='form-control'
                                    {...register('role')}
                                    defaultValue={6}>
                                    <option value='2'>{translate("Adminstrator", "Msimamizi")}</option>
                                    <option value='6'>{translate("Uploader", "Mpandishaji")}</option>
                                    <option value='7'>{translate("Finance", "Mhasibu")}</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className='row my-4'>
                              <div className='col'></div>
                              <div className='col-auto'>
                                {loading ? (
                                  <button className='btn btn-primary'>
                                    <Loader
                                      type='TailSpin'
                                      color='#ffffff'
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className='btn btn-primary'
                                    type='submit'>
                                    {translate("Submit", "Wasilisha")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateStaff;
