import React from 'react';
import { NavLink } from 'react-router-dom';
import { translate } from "../../../components/LanguageTranslation/translate";

export default function Navs() {
  return (
    <ul className="nav nav-tabs">

      <li className="nav-item">
        <NavLink to="/publications/papers" className="nav-link">{translate("Publications", "Machapisho")}</NavLink>
      </li>


      <li className="nav-item">
        <NavLink to="/publications/issues" className="nav-link">{translate("Issues", "Matoleo")}</NavLink>
      </li>

    </ul>
  )
}
