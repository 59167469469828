import { Link, useParams } from "react-router-dom";
import DashboardWrapper from "../../../components/DashboardWrapper";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchIssueAnalysisReport } from "../../../api/reports";
import { DateTime } from "luxon";
import ReactApexChart from "react-apexcharts";
import { translate } from "../../../components/LanguageTranslation/translate";

function IssueAnalysisReport() {
  const params = useParams();
  const location = useLocation();
  const publication = location.state.issueAnalysis;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryData, setSummaryData] = useState();
  const [platform, setPlatform] = useState();
  const [pieTitle, setPieTitle] = useState("Readers Gender");
  const [barTitle, setBarTitle] = useState("Top Country Location");
  const [cityTitle, setCityTitle] = useState("Top City Location");
  const [ageTitle, setAgeTitle] = useState("Readers Age group");
  const [start, setStart] = useState(DateTime.now().startOf("day").toISODate());
  const [end, setEnd] = useState(DateTime.now().endOf("day").toISODate());

  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;
  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).token;
  const company = JSON.parse(localStorage.getItem("RIFALYCOMPANY"));
  var fx = localStorage.getItem("RIFALYCURRENCYRATES")
    ? JSON.parse(localStorage.getItem("RIFALYCURRENCYRATES"))
    : { base: "TZS", rates: null };

  const HOURS = [
    "12am",
    "1am",
    "2am",
    "3am",
    "4am",
    "5am",
    "6am",
    "7am",
    "8am",
    "9am",
    "10am",
    "11am",
    "12pm",
    "1pm",
    "2pm",
    "3pm",
    "4pm",
    "5pm",
    "6pm",
    "7pm",
    "8pm",
    "9pm",
    "10pm",
    "11pm",
  ];

  const handleDateChange = (e) => {
    const value = new Date(e.target.value.trim());
    if (e.target.id === "start") {
      setStart(DateTime.fromJSDate(value).toISODate());
    } else {
      setEnd(DateTime.fromJSDate(value).toISODate());
    }
  };

  async function fetchPublicationAnalytics(options) {
    setIsLoading(true);
    const req = await fetchIssueAnalysisReport(options);

    if (req.status == 200) {
      setSummaryData(req.data);
    }
    setIsLoading(false);
  }

  async function handleSearch() {
    setIsLoading(true);
    const id = params.id;
    const options = {
      id,
      startDate: start,
      endDate: end,
      token: user,
    };

    await fetchPublicationAnalytics(options);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchPublicationAnalytics({
      id: params.id,
      token: user,
      startDate: DateTime.now().startOf("day").toISODate(),
      endDate: DateTime.now().endOf("day").toISODate(),
    });
  }, []);

  // Line Chart Data
  const lineDataReads = [
    {
      name: "Reads",
      data: summaryData?.reads?.map((d) => d.readCount) || [],
    },
  ];

  const optionsReads = {
      chart: {
        id: "Reads",
        title: {
          text: "Reads",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        toolbar: { show: false },
      },
      xaxis: {
        categories: summaryData?.reads?.length
          ? summaryData.reads.map((d) => HOURS[d.hour])
          : [],
      },
  };

  const lineDataDuration = [
    {
      name: "Duration (sec)",
      data: summaryData?.averageDuration?.length
        ? summaryData?.averageDuration.map((d) =>
            // (Number(d.duration) / 60).toFixed(0)
            Number(d.duration).toFixed(0)
          )
        : [],
    },
  ];

  const optionsDuration = {
    chart: {
      id: "Average Duration",
      title: {
        text: "Average Duration (sec)",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },
      fill: {
        colors: ["#00b17a"],
      },
      toolbar: { show: false },
    },
    fill: {
      colors: ["#00b17a"],
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: summaryData?.averageDuration.map((d) => d.page),
    },
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <div className="row">
                <div className="col-12 col-lg-10 col-xl-12">
                  <div className="header mt-md-3">
                    <nav aria-label="breadcrumb mb-2">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/dashboard" onClick={toDashboard}>
                          {translate("Dashboard", "Dashibodi")}
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {translate("Publication Analytics", "Takwimu za Uchambuzi wa Chapisho")} 
                        </li>
                      </ol>
                    </nav>
                    
                    <div className="header-body">
                      <h6 className="header-pretitle">{translate("Overview", "Muhtasari")}</h6>

                      <h1 className="header-title">{`${
                        publication.title
                      } - ${DateTime.fromISO(publication.releaseDate).toFormat(
                        "LLL dd, yyyy"
                      )}`}</h1>
                    </div>
                  </div>
                </div>

                <div className="col-auto align-self-end"></div>
              </div>

              <hr></hr>
            </div>

            <div className="content">
              <div className="row align-items-end my-4">
                <div className="col-md-3">
                  <div className="input-group input-group-merge input-group-reverse">
                    <input
                      type="date"
                      id="start"
                      name="start"
                      onChange={handleDateChange}
                      className="form-control"
                      placeholder="start date"
                    />
                    <div className="input-group-text" id="inputEndDate">
                      <span className="fe fe-clock"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group input-group-merge input-group-reverse">
                    <input
                      type="date"
                      id="end"
                      name="end"
                      onChange={handleDateChange}
                      className="form-control"
                      placeholder="end date"
                    />
                    <div className="input-group-text" id="inputStartDate">
                      <span className="fe fe-clock"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  {isLoading ? (
                    <button className="btn btn-primary">
                      <Loader
                        type="TailSpin"
                        color="#ffffff"
                        height={20}
                        width={20}
                      />
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={handleSearch}>
                      {translate("Search", "Tafuta")}
                    </button>
                  )}
                </div>
              </div>
              
              {isLoading ? (
                <div className="row">
                  <div className="col-md-12 my-4 offset-4 my-4">
                    <Loader
                      type="TailSpin"
                      color="#46E7AD"
                      height={100}
                      width={100}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 my-4">
                    <div className="row">
                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h6 className="text-capitalize text-muted mb-3">
                                {translate("New readers", "Wasomaji Wapya")}
                                </h6>
                                <span className="h2 mb-0 mr-4">
                                  {summaryData?.summary?.uniqueReaders ?? 0}
                                </span>

                                <span className="badge bg-success-soft mr-2 mt-n1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h6 className="text-capitalize text-muted mb-3">
                                {translate("Returning readers", "Wasomaji wanaorudi")}
                                </h6>
                                <span className="h2 mb-0">
                                  {summaryData?.summary?.recurringReaders ?? 0}
                                </span>
                                <span className="badge bg-success-soft mr-2 mt-n1">
                                  {platform}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h5 className="text-capitalize text-muted mb-3">
                                {translate("Highest Read Page", "Ukurasa Ulio Somwa Zaidi")}
                                  
                                </h5>
                                <span className="h2 mb-0">
                                  {summaryData?.summary?.highestReadPage ??
                                    "N/A"}
                                </span>
                                <span className="badge bg-success-soft mr-2 mt-n1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 col-xl">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <h5 className="text-capitalize text-muted mb-3">
                                {translate("Reads", "Usomaji")}
                                </h5>
                                <span className="h2 mb-0">
                                  {summaryData?.reads?.reduce(
                                    (total, { readCount }) =>
                                      total + (readCount || 0),
                                    0
                                  ) ?? 0}
                                </span>
                                <span className="badge bg-success-soft mr-2 mt-n1"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">
                            {translate("Reads Performance", "Utendaji wa Usomaji")} 
                            </h4>
                            <h5 className="card-header-title text-muted mb-3">
                            {translate("Unique Vs Recurring users for the past month", "Watumiaji Kipevu Vs Watumiaji Wanarudi kwa Mwezi Ulio Pita")} 
                            </h5>
                          </div>
                          <div className="card-body">
                            <ReactApexChart
                              type="line"
                              series={lineDataReads}
                              options={optionsReads}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-12">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">
                            {translate("Time spent per page", "Muda ulio tumika kwa kila ukurasa")} 
                            </h4>
                          </div>
                          <div className="card-body">
                            <ReactApexChart
                              type="line"
                              series={lineDataDuration}
                              options={optionsDuration}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-5">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">{barTitle}</h4>
                          </div>
                          <div className="card-body relative">
                            <div className="bars">
                              <ReactApexChart
                                type="bar"
                                series={[
                                  {
                                    data: summaryData?.readingByCountry.map(
                                      (rc) => {
                                        return {
                                          x: rc.country,
                                          y: `${(
                                            (rc.reads * 100) /
                                            summaryData?.readingByCountry.reduce(
                                              (a, b) => a + b.reads,
                                              1
                                            )
                                          ).toFixed(1)}%`,
                                        };
                                      }
                                    ),
                                  },
                                ]}
                                options={{
                                  chart: {
                                    type: "bar",
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  plotOptions: {
                                    bar: {
                                      horizontal: true,
                                      barHeight: 30,
                                      dataLabels: {
                                        position: "center",
                                        offsetX: 50,
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-xl-7">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">{cityTitle}</h4>
                          </div>
                          <div className="card-body relative">
                            <div className="bars">
                              <ReactApexChart
                                type="bar"
                                // series={[
                                //   {
                                //     data: summaryData?.readingByRegion.map((item)=>{item.region.replace("Region","").trim()}).map(
                                //       (rc) => {
                                //         return {
                                //           x: rc.region,
                                //           y: `${(
                                //             (rc.reads * 100) /
                                //             summaryData?.readingByRegion.reduce(
                                //               (a, b) => a + b.reads,
                                //               1
                                //             )
                                //           ).toFixed(1)}%`,
                                //         };
                                //       }
                                //     ),
                                //   },
                                // ]}
                                series={[
                                  {
                                    data: summaryData?.readingByRegion
                                      .reduce((acc, rc) => {
                                        const existingRegion = acc.find(item => item.region === rc.region || rc.region.includes(item.region));
                                
                                        if (existingRegion) {
                                          existingRegion.reads += rc.reads;
                                        } else {
                                          acc.push({ region: rc.region, reads: rc.reads });
                                        }
                                
                                        return acc;
                                      }, [])
                                      .map((rc) => {
                                        const totalReads = summaryData?.readingByRegion.reduce((a, b) => a + b.reads, 1);
                                        return {
                                          x: rc.region,
                                          y: `${((rc.reads * 100) / totalReads).toFixed(1)}%`,
                                        };
                                      }),
                                  },
                                ]}                                
                                options={{
                                  chart: {
                                    type: "bar",
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  plotOptions: {
                                    bar: {
                                      horizontal: true,
                                      barHeight: 30,
                                      dataLabels: {
                                        position: "center",
                                        offsetX: 50,
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="col-12 col-xl-7">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">{ageTitle}</h4>
                          </div>
                          <div className="card-body relative">
                            <div className="bars">
                              <ReactApexChart
                                type="bar"
                                series={[
                                  {
                                    data: summaryData?.readingByRegion.map(
                                      (rc) => {
                                        return {
                                          x: rc.country,
                                          y: `${(
                                            (rc.reads * 100) /
                                            summaryData?.readingByRegion.reduce(
                                              (a, b) => a + b.reads,
                                              1
                                            )
                                          ).toFixed(1)}%`,
                                        };
                                      }
                                    ),
                                  },
                                ]}
                                options={{
                                  chart: {
                                    type: "bar",
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  plotOptions: {
                                    bar: {
                                      horizontal: true,
                                      barHeight: 30,
                                      dataLabels: {
                                        position: "center",
                                        offsetX: 50,
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-5">
                        <div className="card card-fill-sm">
                          <div className="card-header">
                            <h4 className="card-header-title">{pieTitle}</h4>
                          </div>
                          <div className="card-body relative">
                            <div className="Doughnut">
                              <ReactApexChart
                                type="donut"
                                series={[
                                  {
                                    data: summaryData?.readingByRegion.map(
                                      (rc) => {
                                        return {
                                          x: rc.country,
                                          y: `${(
                                            (rc.reads * 100) /
                                            summaryData?.readingByRegion.reduce(
                                              (a, b) => a + b.reads,
                                              1
                                            )
                                          ).toFixed(1)}%`,
                                        };
                                      }
                                    ),
                                  },
                                ]}
                                options={{
                                  chart: {
                                    type: "donut",
                                  },
                                  responsive: [
                                    {
                                      breakpoint: 480,
                                      options: {
                                        chart: {
                                          width: 200,
                                        },
                                        legend: {
                                          position: "right",
                                        },
                                      },
                                    },
                                  ],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
export default IssueAnalysisReport;
