import React from 'react';
import {NavLink} from 'react-router-dom';
import { translate } from "../../../components/LanguageTranslation/translate";

export default function Navs() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;

  return (
    <ul className="nav nav-tabs">

      <li className="nav-item">
        <NavLink to="/reports/revenue" className="nav-link">{translate("Revenue","Mapato")}</NavLink>
      </li>
           
      {[6].includes(parseInt(pid)) ? (
        <li className="nav-item">
          <NavLink to="/reports/corporate-subscriptions" className="nav-link">Corporate Subscriptions</NavLink>
        </li> ) : null}
        {[6, 53, 57, 22, 9, 106].includes(parseInt(pid)) ? (
        <li className="nav-item">
          <NavLink to="/reports/corporate-downloads" className="nav-link">Corporate Downloads</NavLink>
        </li> ) : null}
        {[6, 53, 57, 22, 9, 106].includes(parseInt(pid)) ? (
        <li className="nav-item">
          <NavLink to="/reports/directdownloads" className="nav-link">Direct Downloads</NavLink>
        </li> ) : null}
      {/* {parseInt(pid) === 6 ? */}
      {[6, 53, 57, 22, 9, 106].includes(parseInt(pid)) ? (
        <li className="nav-item">
          <NavLink to="/reports/subscriptiondownloads" className="nav-link">Subscription Downloads</NavLink>
        </li> ) : null}
    </ul>
  )
}
