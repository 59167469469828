
import axios from "axios";
import api_url from "./api_config";
const AUDIO_URL = 'https://api.rifaly.co.tz/api/audio/';
const audioURL = api_url.AUDIO_URL;

const baseURL = api_url.PUBLISHER_URL;
const billingURL = api_url.BILLING_URL; 
const newbillingURL = api_url.NEWBILLING_URL;


const countryApi = axios.create({
  baseURL:api_url.COUNTRIES_URL,
  headers:{"Content-type":"application/json"}
});

const audioApi = axios.create({
  baseURL: audioURL,
  headers: { "Content-type": "application/json" },
});

const api = axios.create({
  baseURL: billingURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiV3 = axios.create({
  baseURL: newbillingURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchRevenueApi = (publisherId,data)=>{
  return api({
    url: `reports/publications-sales-reports/?id=${publisherId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",headers:{'Authorization':`Bearer ${data.token}`}
  });
}
export const fetchPublisherDownloadsApi = (publisherId, data) => {
  return api({
    url: `reports/publisher-downloads/?id=${publisherId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET", headers: {'Authorization': `Bearer ${data.token}`}
  });
}
export const fetchExchangeRatesApi=(base)=>{
    return api({
        url:`exchange_rates/${base}`,method:"GET"
    })
}

export const fetchIssueAnalysisReport = (options) => {
  return apiV3({
    url: `api/v3/analytics/pageAnalytics?id=${options.id}&startDate=${options.startDate}&endDate=${options.endDate}`,
    method: "GET",
    headers: { Authorization: `Bearer ${options.token}` },
  });
};

export const fetchReportSummary = (data) => {
  let url = `api/v1/reports/summary`;

  if (data.audioId) {
    url += `?audioId=${data.audioId}`;
  }
  return audioApi({
    url: url,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchReportPlays = (data) => {
  let url = `api/v1/reports/plays?reportType=${data.reportType}`;

  if (data.audioId) {
    url += `&audioId=${data.audioId}`;
  }
  return audioApi({
    url: url,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

// podcasterId=${data.podcasterId}
export const fetchReportImpressions = (data) => {
  let url = `api/v1/reports/impressions?reportType=${data.reportType}`;

  if (data.audioId) {
    url += `&audioId=${data.audioId}`;
  }
  return audioApi({
    url: url,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

 export const fetchspecificissueReport = (issueId,data)=>{
    return apiV3({
    url:`/api/v3/reports/publisher-issue-summary?id=${issueId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",headers:{'Authorization':`Bearer ${data.token}`}
  });
}
