import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../../components/LanguageTranslation/translate";
import {thousandSeparator} from "./common";

export const OVERVIEWISSUECOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    header: "Issues",
    accessor: "title",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Impression",
    accessor: "impressionCount",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Reads",
    accessor: "downloadCount",
    Cell: (props) => (
      <div className="capitalize">{props.value}</div>
    ),
  },
  {
    header: "Sales (Tzs)",
    accessor: "amount",
    Cell: (props) => <div>{props.value > 0 ? thousandSeparator((parseFloat(props.value)).toFixed(0)) : 0}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Link to={`/dashboard/issues/show/${props.row.original.issueId}`}  state={{ issue: props.row.original }}>
          <button className="btn btn-primary" >
            {translate("View", "Tazama")}
          </button>
        </Link>
      </div>
    ),
  },
];

