import React, { useState } from "react";
import "./style.css";
import { ReactComponent as MootoLogo } from "../../assets/img/logo.svg";
import { ReactComponent as DashIcon } from "../../assets/img/dashboard.svg";
import { ReactComponent as UserIcon } from "../../assets/img/user.svg";
import { ReactComponent as PublisherIcon } from "../../assets/img/publisher.svg";
import { ReactComponent as ReportIcon } from "../../assets/img/Chart.svg";
import { ReactComponent as AudioIcon } from "../../assets/img/audiomic.svg";
import { ReactComponent as ArticlesIcon } from "../../assets/img/articlesLogo.svg";
import { ReactComponent as BooksIcon } from "../../assets/img/booksLogo.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/Setting.svg";
import { menuItems } from "./menuItems";
import SubMenus from "./SubMenus";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { NavLink, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { translate } from "../LanguageTranslation/translate";

export default function Sidebarz() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;

  const location = useLocation();

  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [collapsed, setCollapsed] = useState(true);

  var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

  const handleMenuItemClick = (menuItemId) => {
    setActiveMenuItem(menuItemId);
  };

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="sidenav">
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md "
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleToggleCollapse}
            aria-controls="sidebarCollapse"
            aria-expanded={!collapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon custom-toggler-icon"></span>
          </button>

          <MootoLogo className="mt-3" />

          <hr className="divider" />

          <div
            className={`collapse navbar-collapse ${collapsed ? "" : "show"}`}
            id="sidebarCollapse"
          >
            <Sidebar className="navbar-nav" backgroundColor="#0C2B3B">
              <Menu className="nav-item">
                <MenuItem
                  component={
                    <Link
                      to={`/dashboard`}
                      className={`ps-menu-button ${
                        location.pathname === "/dashboard" ? "active" : ""
                      }`}
                    />
                  }
                  className="nav-link"
                  icon={
                    <i className="fe">
                      <DashIcon />
                    </i>
                  }
                  onClick={() => setActiveMenuItem("dashboard")}
                >
                  <span className="css-12w9als">
                    {translate("Overview", "Muhtasari")}
                  </span>
                </MenuItem>
                {user.role == 2 ? (
                  <MenuItem
                    component={
                      <Link
                        to={`/staffs`}
                        className={`ps-menu-button ${
                          location.pathname === "/staffs" ? "active" : ""
                        }`}
                      />
                    }
                    className="nav-link"
                    icon={
                      <i className="fe">
                        <UserIcon />
                      </i>
                    }
                    onClick={() => setActiveMenuItem("staffs")}
                  >
                    <span className="css-12w9als">
                      {translate("Staff", "Wafanyakazi")}
                    </span>
                  </MenuItem>
                ) : null}
                <MenuItem
                  component={
                    <Link
                      to={`/publications`}
                      className={`ps-menu-button ${
                        location.pathname === "/publications" ? "active" : ""
                      }`}
                    />
                  }
                  className="nav-link"
                  icon={
                    <i className="fe">
                      <PublisherIcon />
                    </i>
                  }
                  onClick={() => setActiveMenuItem("publications")}
                >
                  <span className="css-12w9als">
                    {translate("Publication", "Chapisho")}
                  </span>
                </MenuItem>
                <MenuItem
                  component={
                    <Link
                      to={`/reports`}
                      className={`ps-menu-button ${
                        location.pathname === "/reports" ? "active" : ""
                      }`}
                    />
                  }
                  className="nav-link"
                  icon={
                    <i className="fe">
                      <ReportIcon />
                    </i>
                  }
                  onClick={() => setActiveMenuItem("reports")}
                >
                  <span className="css-12w9als">
                    {translate("Reports", "Ripoti")}
                  </span>
                </MenuItem>
                <MenuItem
                  component={
                    <Link
                      to={`/settings`}
                      className={`ps-menu-button ${
                        location.pathname === "/settings" ? "active" : ""
                      }`}
                    />
                  }
                  className="nav-link"
                  icon={
                    <i className="fe">
                      <SettingsIcon />
                    </i>
                  }
                  onClick={() => setActiveMenuItem("settings")}
                >
                  <span className="css-12w9als">
                    {translate("Settings", "Mpangilio")}
                  </span>
                </MenuItem>
              </Menu>
            </Sidebar>
          </div>
        </div>
      </nav>
    </div>
  );
}
