import React from "react";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import IssueReportTable from "./issueReportTable";
import { translate } from "../../../components/LanguageTranslation/translate";

export default function IssueReport() {
  const navigate = useNavigate();
  const location = useLocation();
  const issue = location.state?.issue;

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      {translate("Dashboard", "Dashibodi")}
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {issue.title}
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h1 className="header-title">
                  {issue.title}
                </h1>
              </div>
            </div>
            <hr />
            <div className="content">
              <IssueReportTable issue={issue}/>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
