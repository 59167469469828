import React from "react";
import { NavLink } from "react-router-dom";
import { translate } from "../../../../components/LanguageTranslation/translate";

const menuItems = [
  {
    name: translate("Staffs", "Wafanyakazi"),
    path: translate("staffs", "wafanyakazi"),
  },
];

function StaffNavs() {
  return (
    <ul className="nav nav-tabs">
      {menuItems.map((menu) => (
        <li className="nav-item" key={menu.path}>
          <NavLink to={"/" + menu.path} className="nav-link">
            {menu.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default StaffNavs;
