import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { REPORTCOLUMNS } from "./ReportColumns";
import EmptyData from "../../../../components/EmptyData/Index";
import ReportFilter from "./ReportFilter";
import { thousandSeparator } from "../common";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import axios from "axios";
import { BILLING_URL, CORPORATES_URL } from "../../../../api/api_config";
import { DateTime } from "luxon";
import { translate } from "../../../../components/LanguageTranslation/translate";

function DownloadsReportTable() {
  const columns = useMemo(() => REPORTCOLUMNS, []);
  const [isToday, setIsToday] = useState(true);
  const [data, setData] = useState([]);
  const [topdata, setTopData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    DateTime.fromMillis(Date.now())
      .startOf("day")
      .toISO({ includeOffset: false })
  );
  const [endDate, setEndDate] = useState(
    DateTime.fromMillis(Date.now()).toISO({ includeOffset: false })
  );

  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;
  var fx = localStorage.getItem("RIFALYCURRENCYRATES")
    ? JSON.parse(localStorage.getItem("RIFALYCURRENCYRATES"))
    : { base: "TZS", rates: null };
  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).token;
  const company = JSON.parse(localStorage.getItem("RIFALYCOMPANY"));
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    const exportData = data.map((d, i) => {
      return {
        sn: i,
        username: d.username,
        paper: d.title,
        "Download Date": new Date(d.createdAt).toLocaleDateString(),
        "Download Type": d.readerType,
      };
    });
    console.log("export", exportData);
    let ws = XLSX.utils.json_to_sheet(exportData);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly-Report ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  const handleSearch = () => {
    loadData();
  };
  const handleDateChange = (e) => {
    if (e.target.id === "start")
      setStartDate(new Date(e.target.value.trim()).toISOString());
    else setEndDate(new Date(e.target.value.trim()).toISOString());
    setIsToday(false);
  };
  const loadData = async () => {
    setLoading(true);
    let d = { endDate: endDate, startDate: startDate };
    d.token = user.token;
    try {
      const result = await axios.get(
        `${BILLING_URL}reports/publisher-downloads?id=${company.id}&startDate=${startDate}&endDate=${endDate}`,
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      const data = result.data.data.subscriptions.map((d) => {
        const item = {
          id: d.id,
          title: d.paper.title,
          createdAt: d.createdAt,
          username: d.username,
          readerType: d.readerType === "Wallet" ? "Direct" : d.readerType,
        };
        return item;
      });

      setData(
        data.sort((a, b) => {
          return a.id > b.id ? 1 : -1;
        })
      );
    } catch (error) {
      toast.error("Oops! Something went wrong", {
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        toastId: 111,
      });
    }
    setLoading(false);
  };
  const loadSubscriptionsData = async () => {
    setLoading(true);
    let d = { endDate: endDate, startDate: startDate };
    d.token = user.token;
    try {
      const result = await axios.get(
        `${CORPORATES_URL}reports/publisher/${company.id}`,
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      const topdata = result.data.data;
      setTopData(topdata);
    } catch (error) {
      toast.error("Oops! Something went wrong", {
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        toastId: 111,
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    loadData();
    loadSubscriptionsData();
  }, []);

  const numberofdata = data.length;

  return (
    <div>
      <div className="row">
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        {translate(
                          "Total Subscription Downloads",
                          "Jumla ya Vifurushi Vilivyopakuliwa"
                        )}
                      </h6>
                      <span className="h2 mb-0 mr-4">
                        {numberofdata ? numberofdata : 0}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-end my-4">
        <div className="col">
          <ReportFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>

        <div className="col-md-3">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              type="date"
              id="start"
              name="start"
              onChange={handleDateChange}
              className="form-control"
              placeholder="start date"
            />
            <div className="input-group-text" id="inputEndDate">
              <span className="fe fe-clock"></span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              type="date"
              id="end"
              name="end"
              onChange={handleDateChange}
              className="form-control"
              placeholder="end date"
            />
            <div className="input-group-text" id="inputStartDate">
              <span className="fe fe-clock"></span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          {loading ? (
            <button className="btn btn-primary">
              <Loader type="TailSpin" color="#ffffff" height={20} width={20} />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={handleSearch}>
              {translate("Search", "Tafuta")}
            </button>
          )}
        </div>
      </div>

      <div className="row mt-2 body-content">
        {loading ? (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>{translate("Loading data...", "Inapakia Taarifa...")}</p>
          </div>
        ) : data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                      {translate("Download Report for", "Pakua Ripoti ya")}{" "}
                      {isToday
                        ? "Today"
                        : `${new Date(
                            startDate
                          ).toLocaleDateString()} to ${new Date(
                            endDate
                          ).toLocaleDateString()}`}
                    </h4>

                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleExport}
                      >
                        {translate("Export", "Pakua")}
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    {translate("Showing", "Inaonyesha")}{" "}
                    {pageIndex === 0 ? 1 : pageIndex * pageSize + 1}{" "}
                    {translate("to", "kwa")}{" "}
                    {pageIndex > 10
                      ? data.length
                      : pageIndex * pageSize + pageSize > data.length
                      ? data.length
                      : pageIndex * pageSize + pageSize}{" "}
                    {translate("of", "ya")} {data.length}{" "}
                    {translate("elements", "vipengele")}
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">
                    {translate("Page", "Ukurasa")} {pageIndex + 1}
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData
            name={
              translate("Reports for", "Ripoti ya") +
              (isToday
                ? translate("today", "leo")
                : `${new Date(startDate).toLocaleDateString()} to ${new Date(
                    endDate
                  ).toLocaleDateString()}`)
            }
            path="/"
          />
        )}
      </div>
    </div>
  );
}

export default DownloadsReportTable;
