import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { REPORTCOMPANYCOLUMNS } from "./CorporateSubscriptionTableColumns";
import EmptyData from "../../../../components/EmptyData/Index";
import ReportFilter from "./ReportFilter";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import axios from "axios";
import { BILLING_URL } from "../../../../api/api_config";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Navs from "../navs";
import { translate } from "../../../../components/LanguageTranslation/translate";

function CorporateSubscriptionTable() {
  const columns = useMemo(() => REPORTCOMPANYCOLUMNS, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonthDigit] = useState(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const startYear = 2023;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  ).sort((a, b) => {
    if (b > a) return 1;
    else if (a > b) return -1;
    else return 0;
  });

  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).token;
  const company = JSON.parse(localStorage.getItem("RIFALYCOMPANY"));
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    const exportData = data.map((d, i) => {
      return {
        sn: i,
        "Company Name": d.companyName,
        "Number of staff": d.staffCount,
        "Monthly Rate": d.price,
        "MCL Commission": d.totalPrice.toFixed(2),
      };
    });
    let ws = XLSX.utils.json_to_sheet(exportData);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly-Report ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const handleSeach = () => {
    setLoading(true);
    loadData();
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const loadData = async () => {
    let d = { selectedMonth: selectedMonth, selectedYear: selectedYear };
    d.token = user.token;
    var data = [];
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    if (selectedMonth < 10 && selectedYear <= 2023) {
      data = [];
      setData(data);
    } else {
      try {
        const result = await axios.get(
          `${BILLING_URL}reports/publisher/sale-reports?id=${company.id}&month=${selectedMonth}&year=${selectedYear}`,
          { headers: { Authorization: `Bearer ${user.token}` } }
        );

        data = result.data.data
          .map((d) => {
            const item = {
              id: d.id,
              staffCount: d.staffCount,
              companyName: d.companyName,
              startDate: "2023-10-23",
              endDate: "2024-10-31",
              price: d.price * 12,
              totalPrice: d.totalPrice * 12,
              commission:
                parseInt(company.commission) * 0.01 * d.totalPrice * 12,
            };
            return item;
          })
          .filter((item) => item.staffCount > 0);

        setData(
          data.sort((a, b) => {
            return a.id > b.id ? 1 : -1;
          })
        );
      } catch (error) {
        toast.error("Oops! Something went wrong", {
          hideProgressBar: true,
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: false,
          toastId: 111,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const navigate = useNavigate();

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }
  const handleMonthChange = (e) => {
    setSelectedMonthDigit(e.target.value);
  };
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      {translate("Dashboard", "Dashibodi")}
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translate("Reports", "Ripoti")}
                  </li>
                </ol>
              </nav>
              <div className="header-body">
                <h6 className="header-pretitle">
                  {translate("Overview", "Muhtasari")}
                </h6>
                <h1 className="header-title">
                  {translate("Reports", "Ripoti")}
                </h1>
              </div>
            </div>
            <div className="content">
              <Navs></Navs>
              <div>
                <div className="row align-items-end my-4">
                  <div className="col">
                    <ReportFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      setGlobalFilter={setGlobalFilter}
                      globalFilter={globalFilter}
                    />
                  </div>
                  <div className="col-md-3">
                    <select
                      id="month"
                      className="form-select"
                      onChange={handleMonthChange}
                      value={selectedMonth}
                    >
                      <option value="">{translate("Select Month", "Chagua Mwezi")}</option>
                      {months.map((month, index) => (
                        <option key={index} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge input-group-reverse">
                      <select
                        id="year"
                        className="form-select"
                        onChange={handleYearChange}
                        value={selectedYear}
                      >
                        <option value="">{translate("Select Year", "Chagua Mwaka")}</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    {loading ? (
                      <button className="btn btn-primary">
                        <Loader
                          type="TailSpin"
                          color="#ffffff"
                          height={20}
                          width={20}
                        />
                      </button>
                    ) : (
                      <button className="btn btn-primary" onClick={handleSeach}>
                        {translate("Search","Tafuta")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="row mt-2 body-content">
                  {data && data.length >= 1 ? (
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-header-title">
                              {translate("Download Report for","Pakua Ripoti ya")} {months[selectedMonth - 1]}{" "}
                                {selectedYear}
                              </h4>
                              <div>
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={handleExport}
                                >
                                  {translate("Export","Pakua")}
                                </button>
                              </div>
                            </div>
                            <table
                              {...getTableProps()}
                              className="table table-sm card-table"
                            >
                              <thead>
                                {headerGroups.map((headerGroup) => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                      <th
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                      >
                                        {column.render("header")}
                                      </th>
                                    ))}
                                  </tr>
                                ))}
                              </thead>
                              <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                  prepareRow(row);
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => {
                                        return (
                                          <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col"></div>
                        <div className="col-auto">
                          <div>
                            <span className="table-stat">
                            {translate("Showing", "Inaonyesha")}{" "}
                              {pageIndex === 0 ? 1 : pageIndex * pageSize + 1}{" "}
                              {translate("to", "kwa")}{" "}
                              {pageIndex > 10
                                ? data.length
                                : pageIndex * pageSize + pageSize > data.length
                                ? data.length
                                : pageIndex * pageSize + pageSize}{" "}
                              {translate("of", "ya")} {data.length} {translate("elements", "vipengele")}
                            </span>
                            <button
                              className="btn btn-round btn-sm btn-pagination"
                              onClick={() => previousPage()}
                            >
                              <i className="fe fe-chevron-left"></i>
                            </button>
                            <span className="table-stat">
                            {translate("Page", "Ukurasa")} {pageIndex + 1}
                            </span>
                            <button
                              className="btn btn-round btn-sm btn-pagination"
                              onClick={() => nextPage()}
                            >
                              <i className="fe fe-chevron-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <EmptyData
                      name={`Reports for ${
                        months[selectedMonth - 1]
                      } ${selectedYear}`}
                      path="/"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CorporateSubscriptionTable;
