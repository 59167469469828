import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { updateStaffsApi } from "../../../../api/user";
import { toast } from "react-toastify";
import { translate } from "../../../../components/LanguageTranslation/translate";

function StaffEdit(props) {
  var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var staff = props.data;
  staff.first_name = staff.fullName.split(" ")[0];
  staff.last_name = staff.fullName.split(" ")[1];
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = { id: staff.id };
    let options = { authorization: user.token };
    let form = document.getElementById("editForm");
    let inputs = Array.from(form.elements);
    data.fullName = inputs[0].value.trim() + " " + inputs[1].value.trim();
    data.email = inputs[2].value.trim();
    data.phone = inputs[3].value.trim();
    data.role = inputs[4].value;
    if (inputs[5].value && inputs[5].value.length > 0)
      data.password = inputs[5].value;

    updateStaffsApi(data, options)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleClose();
        history("/staffs");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.respose) {
          toast.error(error.response.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        {translate("Edit", "Hariri")}
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="">
            <h6 className="header-pretitle">{translate("Edit", "Hariri")}</h6>

            <h1 className="header-title">{translate("Edit Staff", "Hariri Mfanyakazi")}{staff.last_name}</h1>
          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form id="editForm" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    {translate("First Name", "Jina la kwanza")}
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    id="firstName"
                    placeholder="Enter First name"
                    autoFocus
                    defaultValue={staff.first_name}
                    required
                    name="firstName"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    {translate("Last Name", "Jina la mwisho")}
                  </label>
                  <input
                    type="text"
                    className={"form-control"}
                    id="lastName"
                    placeholder="Enter Last name"
                    defaultValue={staff.last_name}
                    required
                    name="lastName"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    {translate("Email Address", "Barua Pepe")}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email Address"
                    defaultValue={staff.email}
                    required
                    name="email"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    {translate("Phone Number", "Nambari ya Simu")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Enter Phone Number"
                    required
                    defaultValue={staff.phone}
                    name="phone"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">
                    {translate("Role", "Jukumu")}
                  </label>
                  <select
                    className="form-control"
                    name="role"
                    id="role"
                    defaultValue={staff.role}
                  >
                    <option value="7">{translate("Finance", "Mhasibu")}</option>
                    <option value="6">{translate("Uploader", "Mpandishaji")}</option>
                    <option value="2">{translate("Adminstrator", "Msimamizi")}</option>
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">
                  {translate("Password", "Nenosiri")}
                  </label>
                  <input
                    defaultValue={staff.password}
                    type={"password"}
                    className="form-control"
                    id="password"
                    placeholder="Enter new password"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {loading ? (
                  <button className="btn btn-primary ml-2">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20}
                    />
                  </button>
                ) : (
                  <Button
                    className="btn btn-primary ml-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {translate("Save", "Hifadhi")}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default StaffEdit;
