import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ReactComponent as Smile } from "../../../../assets/smile.svg";
import { useForm } from "react-hook-form";
import { updateStaffsApi } from "../../../../api/user";
import { fetchStaffs } from "../../../../redux-store/Actions/staff-action";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { translate } from "../../../../components/LanguageTranslation/translate";

function UpdatePublisherStaff(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const action = props.data.status === "ACTIVE" ? "Suspend" : "Activate";

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      id: props.data.id,
      status: props.data.status,
      companyId: props.data.companyId,
    };
    data.status =
      props.data.status && props.data.status.toUpperCase() === "ACTIVE"
        ? "INACTIVE"
        : "ACTIVE";
    console.table(data);

    updateStaffsApi(data)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        handleClose();

        dispatch(fetchStaffs(data.companyId));

        toast.success(
          `Publisher ${
            action === "Suspend" ? "Suspended" : "Activated"
          } successfully`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        navigate("/staffs", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <span className="update-link" onClick={handleShow}>
        {action}
      </span>

      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modal-90w"
      >
        <div className="row">
          <div className="col"></div>
          <div className="col-auto">
            <div className="close-btn">
              <button
                type="button"
                className="btn-close mr-5"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div className="modal-image-holder">
            <Smile />
          </div>

          <div className="modal-details">
            <h2>{translate("Are You Sure ?", "Je, Una Uhakika?")}</h2>
            <p>
              {translate("Are you sure you want to", "Je, una uhakika unataka")}{" "}
              {action}: <b>{props.data.fullName}</b>
            </p>
          </div>

          <div className="bottom-space">
            <div className="left">
              <button
                className="btn btn-outline-danger btn-small text-black"
                onClick={handleClose}
              >
                {translate("Cancel", "Ghairi")}
              </button>
            </div>
            <div className="col-auto">
              <form className="p-3">
                {loading ? (
                  <span className="btn-primary btn-small">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20}
                    />
                  </span>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary btn-small"
                  >
                    {translate("Yes", "Ndio")}, {action}
                  </button>
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdatePublisherStaff;
