import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { translate } from "../../../../components/LanguageTranslation/translate";

function StaffDetail(props) {
  var user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var staff = props.data;
  staff.first_name = staff.fullName.split(" ")[0];
  staff.last_name = staff.fullName.split(" ")[1];
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        {translate("View", "Tazama")}
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="">
            <h6 className="header-pretitle">{translate("View", "Tazama")}</h6>

            <h1 className="header-title">
              {staff.last_name}'s {translate("Details", "Maelezo")}
            </h1>
          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    {translate("First Name", "Jina la kwanza")}
                  </label>
                  <input
                    disabled
                    type="text"
                    className={"form-control"}
                    id="firstName"
                    placeholder="Enter First name"
                    autoFocus
                    defaultValue={staff.first_name}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    {translate("Last Name", "Jina la mwisho")}
                  </label>
                  <input
                    disabled
                    type="text"
                    className={"form-control"}
                    id="lastName"
                    placeholder="Enter Last name"
                    defaultValue={staff.last_name}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    {translate("Email Address", "Barua Pepe")}
                  </label>
                  <input
                    disabled
                    type="email"
                    className={"form-control"}
                    id="email"
                    placeholder="Enter Email Address"
                    defaultValue={staff.email}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    {translate("Phone Number", "Nambari ya Simu")}
                  </label>
                  <input
                    disabled
                    type="text"
                    className={"form-control"}
                    id="phone"
                    placeholder="Enter Phone Number"
                    defaultValue={staff.phone}
                  />

                  <p className="errors">{}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">
                    {translate("Role", "Jukumu")}
                  </label>
                  <select
                    className="form-control"
                    defaultValue={staff.role}
                    disabled
                  >
                    <option value="7">{translate("Finance", "Mhasibu")}</option>
                    <option value="6">{translate("Uploader", "Mpandishaji")}</option>
                    <option value="2">{translate("Adminstrator", "Msimamizi")}</option>
                  </select>

                  <p className="errors">{}</p>
                </div>
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default StaffDetail;
