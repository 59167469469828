import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchCompanyDetails } from "../../../../redux-store/Actions/company-action";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyIssueCategoriesApi,
  fetchCompanyIssuesApi,
  updatePublicationsApi,
} from "../../../../api/publisher";
import { Document, Page, pdfjs } from "react-pdf";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import api_config from "../../../../api/api_config";
import PdfWebViewer from "./PdfWebViewer";
import axios from "axios";
import { DateTime } from "luxon";
import { translate } from "../../../../components/LanguageTranslation/translate";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const schema = yup
  .object({
    // publisherId: yup.integer(),required().label("Promotion Title")
  })
  .required();

export default function EditPublication(props) {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);

  const data = company;

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [changeVisibility, setChangeVisibility] = useState(false);

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [topStories, setTopStories] = useState(["first"]);
  const [showAddStoriesButton, setshowAddStoriesButton] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [publication, setPublication] = useState(location.state);
  const [selectedIssue, setselectedIssue] = useState(publication.issue);
  const [cat, setCat] = useState(publication.categoryId);
  const [issues, setIssues] = useState([]);
  const [issueNo, setIssueNo] = useState(publication.issueNo);

  const [publishDate, setPublishDate] = useState(
    DateTime.fromMillis(publication.publication_timestamp)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toJSDate()
  );

  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
  const updatePublication = async (data) => {
    const config = {
      headers: { Authorization: `Bearer ${user.token}` },
    };
    let result = await updatePublicationsApi(data, config);

    return result;
  };

  //trial edit publication
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(true);

  const [publicationFile, setPublicationFile] = useState(null);
  const [loadedFile, setLoadedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setLoadedFile(file);

    const reader = new FileReader();

    reader.onabort = () => console.log("file reader aborted");
    reader.onerror = () => console.log("file reader failed");

    reader.onload = () => {
      const base64String = reader.result;

      setPublicationFile({ url: base64String });
      setShow(true);
    };

    reader.readAsDataURL(file);
  };

  //end of trial edit publication

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {};
    data.releaseDate = publishDate;
    data.publisherId = company.id;
    data.id = publication.id;
    data.categoryId = cat && parseInt(cat) > 0 ? cat : publication.categoryId;
    data.issueId = selectedIssue ? selectedIssue.id : publication.issue.id;
    data.issueNo = issueNo ? issueNo : publication.issueNo;

    if (topStories && topStories.length > 0) {
      for (let i = 0; i < topStories.length; i++) {
        let tp = topStories[i];
        if (publication.publication_story.length > 0) {
          let mystory = publication.publication_story[i];
          let story = document.getElementById(`${tp}${i}`).value.trim();
          if (story && story.length > 0 && story != mystory) {
            data[`${tp}Story`] = story;
          }
        }
      }
    }
    console.log("🚀 ~ file: EditPublication.js:114 ~ onSubmit ~ data", data);
    console.log("🚀 ~ file: EditPublication.js:114 ~ onSubmit ~ data", data);
    if (
      !data.publisherId ||
      !data.issueId ||
      !data.categoryId ||
      data.categoryId == "0" ||
      data.issueId == "0"
    ) {
      toast.error("Please provide all required information", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    } else {
      if (
        publicationFile &&
        !publication.document.includes(publicationFile.name)
      ) {
        data.publicationFile = publicationFile;
      } else {
        data.publicationFile = publication.publicationFile;
      }
      updatePublication(data)
        .then((res) => {
          console.log("res: ", res.data);

          e.target.reset();
          setLoading(false);
          let message = "";
          if (res.data.status == 403 || res.data.status == 409) {
            message = res.data.error;
            toast.error(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            message = res.data.message;
            toast.success(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            navigate("/publications");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          toast.error("Failed to upate publication", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    let companyId = JSON.parse(
      localStorage.getItem("RIFALYPUBLISHER")
    ).companyId;
    console.log("id: ", companyId, data.id);
    setPublication(location.state);
    dispatch(fetchCompanyDetails(companyId));
    fetchCompanyIssueCategoriesApi(companyId).then((result) => {
      console.log(result.data.data);
      const newCats = result.data.data;
      //set categories
      setCategories(newCats);
      // setIssues(issues.filter(is=>is.categoryId == cat))
    });
    fetchCompanyIssuesApi(companyId, publication.categoryId).then((result) => {
      console.log(result.data.data);
      const newIssues = result.data.data.filter(
        (i) => i.categoryId == publication.categoryId
      );
      //set data
      setIssues(newIssues);
      setselectedIssue(newIssues.length > 0 ? newIssues[0] : publication.issue);
    });
  }, [dispatch]);

  useEffect(() => {
    topStories.length === 3
      ? setshowAddStoriesButton(false)
      : setshowAddStoriesButton(true);
  }, [topStories.length]);

  const findIssue = (issues, id) => {
    return issues.find((element) => {
      return element.id === parseInt(id);
    });
  };

  const addStory = () => {
    console.log(topStories.length);

    switch (topStories.length) {
      case 1:
        setTopStories([...topStories, "second"]);
        break;

      case 2:
        setTopStories([...topStories, "third"]);
        break;

      default:
        break;
    }
  };

  const handleCancelForm = (e) => {
    e.preventDefault();
    navigate("/publications");
  };

  const catChange = (e) => {
    console.log(e.target.value);
    const catId = e.target.value;
    setCat(catId);
    setIssues([]);
    // fetch categories in the pubs
    fetchCompanyIssuesApi(data.id, catId).then((result) => {
      console.log(result.data.data);
      const newIssues = result.data.data;
      //set data
      setIssues(newIssues);
      setselectedIssue(newIssues.length > 0 ? newIssues[0] : publication.issue);
    });
  };

  const issueChange = (e) => {
    var selected = e.target.value;
    const iss = findIssue(issues, selected);
    setselectedIssue(iss);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePicker = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        selected={publishDate}
        dateFormat={"dd-MM-yyyy HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={5}
        onChange={(date) => setPublishDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };
  const handleCoverClick = (e) => {
    document.getElementById("pdfFileInput").click();
  };
  function readFile(e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reader aborted");

      reader.onerror = () => console.log("file reader failed");

      reader.onload = () => {
        const dataUrl = reader.result;
        console.log(dataUrl);
        // setShow(true);
        setLoadedFile({ url: dataUrl });
      };
      reader.readAsDataURL(file);
    }
  }
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      {translate("Dashboard", "Dashibodi")}
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item"
                    aria-current="page"
                    onClick={toPage("/publications")}
                  >
                    {translate("Publications", "Machapisho")}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translate("Edit Publication", "Hariri Chapisho")}
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="card pt-3">
                  <div className="card-heade px-4 pt-4">
                    <div className="">
                      <h6 className="header-pretitle">
                        {translate("Overview", "Muhtasari")}
                      </h6>

                      <h1 className="header-title">
                        {translate("Edit Publication", "Haririr Chapisho")}
                      </h1>
                    </div>

                    <hr />
                  </div>

                  <div className="card-body">
                    <form onSubmit={handleSubmit} className="p-3">
                      <div className="row mb-3">
                        <div className="col-md-6">
                          {publication && publication.cover ? (
                            <img
                              className="viewer-mini"
                              src={`${api_config.STORAGE_URL}${publication.cover}`}
                              alt={publication.issue.title}
                            />
                          ) : null}
                          <section>
                            <div className="upload-editcontent p-4">
                              <label
                                htmlFor="file-upload"
                                className="btn btn-sm btn-outline-primary"
                              >
                                {translate(
                                  "Edit Pdf file",
                                  "Badilisha faili ya PDF"
                                )}
                              </label>
                              <input
                                type="file"
                                id="file-upload"
                                accept=".pdf"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                            </div>
                          </section>{" "}
                          {loadedFile ? (
                            <div>{loadedFile.name}</div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {publicationFile && publicationFile.url ? (
                            <Document
                              file={publicationFile.url}
                              onLoadSuccess={() => setCurrentPage(1)}
                            >
                              <Page
                                className="viewer-mini"
                                scale={0.25}
                                pageNumber={
                                  currentPage && currentPage > 0
                                    ? currentPage
                                    : 1
                                }
                              />
                            </Document>
                          ) : null}
                        </div>
                      </div>
                      <div className="col">
                        <div className=" mb-3">
                          {publicationFile && publicationFile.url ? (
                            <PdfWebViewer
                              onHide={() => setShow(false)}
                              show={show}
                              link={publicationFile.url}
                              size={"small"}
                            />
                          ) : (
                            "No Preview"
                          )}
                        </div>
                      </div>

                      <div className="row g-3 mb-3">
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label htmlFor="company" className="form-label">
                              {translate("Publisher", "Mchapishaji")}
                            </label>
                            <select
                              disabled
                              value={data.id}
                              className="form-select"
                            >
                              {
                                <option key={company.id} value={company.id}>
                                  {company.companyName}{" "}
                                </option>
                              }
                            </select>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            {translate("Category", "Toleo")}
                          </label>
                          <select
                            onChange={catChange}
                            className="form-select"
                            value={cat}
                          >
                            <option disabled value={"0"}>
                              {translate("Select Category", "Chagua Toleo")}
                            </option>
                            {categories.map((category) => (
                              <option
                                value={parseInt(category.categoryId)}
                                key={category.categoryId}
                              >
                                {" "}
                                {category.Name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            {translate("Title", "Kichwa cha Habari")}
                          </label>
                          <select
                            value={selectedIssue.id}
                            onChange={issueChange}
                            className="form-select"
                          >
                            <option value={"0"} disabled>
                              {translate(
                                "Select Issue Title",
                                "Chagua Aina ya Toleo"
                              )}
                            </option>
                            {issues.map((issue) => (
                              <option key={issue.id} value={issue.id}>
                                {" "}
                                {issue.title}{" "}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            {translate(
                              "Date and Time of Release",
                              "Tarehe na Wakati wa Kutolewa"
                            )}
                          </label>
                          <br />
                          <CustomDatePicker
                            className="form-control"
                            dateFormat={"dd-MM-yyyy H:mm:ss"}
                            selected={publishDate}
                            minDate={new Date().getTime}
                            showTimeSelect
                            timeIntervals={5}
                            onChange={(date) => setPublishDate(date)}
                            filterDate={filterPassedDate}
                            filterTime={filterPassedTime}
                            readOnly
                          />
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            {translate("Price", "Bei")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="validationServer02"
                            placeholder="Issue Price"
                            value={selectedIssue.price}
                            disabled
                          />
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <label
                            htmlFor="validationServer02"
                            className="form-label"
                          >
                            {translate("Issue No", "Namba ya toleo")}
                          </label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setIssueNo(e.target.value.trim());
                            }}
                            defaultValue={publication.issueNo}
                            className="form-control"
                            id="issueNo"
                            placeholder="Issue No"
                            required
                          />
                        </div>

                        {topStories.map((topStory, i) => (
                          <div key={i} className="col-12 col-md-12">
                            <div className="mb-1">
                              <label
                                htmlFor="validationServer02"
                                className="form-label capitalize"
                              >
                                {topStory === "first" ? "" : topStory}{" "}
                                {translate("Top Story", "Stori ya mbele")}
                              </label>
                              <textarea
                                type="text"
                                rows="5"
                                className="form-control"
                                id={`${topStory}${i}`}
                                placeholder="Enter top story of the newspaper"
                              ></textarea>
                            </div>
                          </div>
                        ))}

                        <div className="col-12 col-md-12">
                          <div className="">
                            {showAddStoriesButton && (
                              <span className="btn btn-link" onClick={addStory}>
                                + {translate("Add more", "Ongeza zaidi")}{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col"></div>
                        <div className="col-auto">
                          <div className="d-flex flex-column flex-md-row align-items-md-end">
                            {!loading && (
                              <button
                                onClick={handleCancelForm}
                                className="btn btn-outline-secondary mb-2 mb-md-0 me-md-2"
                              >
                                {translate("Cancel", "Ghairi")}
                              </button>
                            )}

                            {loading ? (
                              <button className="btn btn-primary mb-2 mb-md-0">
                                <Loader
                                  type="TailSpin"
                                  color="#ffffff"
                                  height={20}
                                  width={20}
                                />
                              </button>
                            ) : (
                              <button className="btn btn-primary" type="submit">
                                {translate("Update", "Update")}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
