import React from "react";
import "./style.css";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import avatar from "./avatar.png";
import api_config from "../../api/api_config";
import LanguageSwitcher from "../LanguageTranslation/languageSwitcher";
import { translate } from "../LanguageTranslation/translate";

export default function Navbar({ children }) {
  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/signout", { replace: true });
  };

  const translateRole = (roleId) => {
    let roles = [
      { id: 2, role: translate("Admin", "Msimamizi") },
      { id: 6, role: translate("Uploader", "Mpandishaji") },
      { id: 7, role: translate("Finance", "Mhasibu") },
    ];
    let role = roles.find((r) => r.id == roleId);
    return role ? role.role : roleId;
  };

  if (!user) {
    return <Navigate to="/signout" replace />;
  }

  return (
    <nav className="navbar navbar-expand-lg fixed-top" id="topnav">
      <div className="container">
        {/* <button
          className='navbar-toggler me-auto'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbar'
          aria-controls='navbar'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button> */}

        <div className="x-auto"></div>

        <ul className="navbar-nav mx-auto d-none">
          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>{translate("Publish", "Chapisha")}</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>{translate("Resources", "Rasilimali")}</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>{translate("Support", "Msaada")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>{translate("Account", "Akaunti")}</span>
            </NavLink>
          </li>
        </ul>

        <div className="navbar-user d-flex align-items-center">
          <LanguageSwitcher />
          <div className="vr mx-4" style={{ height: "50px" }}></div>
          <Dropdown className="pointer">
            <Dropdown.Toggle as={"div"}>
              <div className="nav-user-holder">
                <div className="user-title">
                  <p className="ut-title">
                    {translateRole(parseInt(user.role))}
                  </p>
                  <p className="ut-name">{`${user.first_name} ${user.last_name}`}</p>
                </div>

                <div className="user-avatar d-flex justify-content-center align-items-center">
                  <img
                    src={
                      user.avatar == "avatar.png"
                        ? avatar
                        : `${api_config.STORAGE_URL}profiles/${user.avatar}`
                    }
                    className="avatar-img rounded-circle"
                    alt="..."
                  />
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/profile">
                {translate("Profile", "Wasifu")}
              </Dropdown.Item>
              <Dropdown.Item href="/settings">
                {translate("Settings", "Mpangilio")}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>
                {translate("Logout", "Ondoka")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
}
