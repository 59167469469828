import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { ISSUEREPORTCOLUMNS } from "./issueReportColumn";
import EmptyData from "../../../components/EmptyData/Index";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { DateTime } from "luxon";
import { translate } from "../../../components/LanguageTranslation/translate";
import { fetchspecificissueReport } from "../../../api/reports";

function IssueReportTable({ issue }) {
  const columns = useMemo(() => ISSUEREPORTCOLUMNS, []);
  const mainissue = issue;
  const issueId = mainissue?.issueId;
  const [isToday, setIsToday] = useState(true);
  const [data, setData] = useState([]);
  const [tilesdata, setTiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    DateTime.fromMillis(Date.now())
      .startOf("day")
      .toISO({ includeOffset: false })
  );
  const [endDate, setEndDate] = useState(
    DateTime.fromMillis(Date.now()).toISO({ includeOffset: false })
  );
  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;
  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).token;
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const thousandSeparator = (x) => {
    return typeof x == "string" || !isNaN(x)
      ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : x;
  };

  const handleSearch = () => {
    loadData(issueId);
  };

  const handleDateChange = (e) => {
    if (e.target.id === "start")
      setStartDate(new Date(e.target.value.trim()).toISOString());
    else setEndDate(new Date(e.target.value.trim()).toISOString());
    setIsToday(false);
  };

  const loadData = async (issueId) => {
    setLoading(true);

    const formattedStartDate = DateTime.fromISO(startDate).toFormat(
      "yyyy-MM-dd HH:mm:ss"
    );
    const formattedEndDate = DateTime.fromISO(endDate).toFormat(
      "yyyy-MM-dd HH:mm:ss"
    );

    const requestData = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      token: user,
    };

    try {
      const result = await fetchspecificissueReport(issueId, requestData);
      const fetchedData = result?.data?.data || {};
      setTiles(fetchedData);
      setData(fetchedData?.publications || []);
    } catch (error) {
      toast.error("Oops! Something went wrong", {
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        toastId: 111,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData(issueId);
  }, [issueId]);

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col-md-3">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              type="date"
              id="start"
              name="start"
              onChange={handleDateChange}
              className="form-control"
              placeholder="start date"
            />
            <div className="input-group-text" id="inputEndDate">
              <span className="fe fe-clock"></span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-group input-group-merge input-group-reverse">
            <input
              type="date"
              id="end"
              name="end"
              onChange={handleDateChange}
              className="form-control"
              placeholder="end date"
            />
            <div className="input-group-text" id="inputStartDate">
              <span className="fe fe-clock"></span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          {loading ? (
            <button className="btn btn-primary">
              <Loader type="TailSpin" color="#ffffff" height={20} width={20} />
            </button>
          ) : (
            <button className="btn btn-primary" onClick={handleSearch}>
              {translate("Search", "Tafuta")}
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-4">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        {translate("Sales", "Mauzo")}
                      </h6>

                      <span className="h2 mb-0">
                        Tzs {thousandSeparator(Math.round(tilesdata?.sales ?? 0))}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        {translate("Impression", "Utazamaji")}
                      </h6>

                      <span className="h2 mb-0">
                        {thousandSeparator(tilesdata?.impressions ?? 0)}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        {translate("Reads", "Usomaji")}
                      </h6>

                      <span className="h2 mb-0">
                        {thousandSeparator(tilesdata?.reads ?? 0)}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        {translate("Unique readers", "Wasomaji wa kipevu")}
                      </h6>

                      <span className="h2 mb-0">
                        {thousandSeparator(tilesdata?.newReaders ?? 0)}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        {translate("Recurring readers", "Wasomaji wa kurudia")}
                      </h6>

                      <span className="h2 mb-0">
                        {thousandSeparator(tilesdata?.returningReaders ?? 0)}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2 body-content">
        {loading ? (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>Loading data...</p>
          </div>
        ) : data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Publications</h4>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? data.length
                      : pageIndex * pageSize + pageSize > data.length
                      ? data.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {data.length} elements
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">Page {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name={"Issue Reports"} path="/" />
        )}
      </div>
    </div>
  );
}

export default IssueReportTable;
