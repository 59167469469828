import React, { useMemo, useEffect } from "react";
import { fetchStaffs } from "../../../../redux-store/Actions/staff-action";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, usePagination } from "react-table";
import { ReactComponent as Empty } from "../../../../assets/empty.svg";
import { translate } from "../../../../components/LanguageTranslation/translate";
import { STAFFCOLUMNS } from "./StaffColumns";
import { Link } from "react-router-dom";

let user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));

function StaffTable() {
  const columns = useMemo(() => STAFFCOLUMNS, []);
  const dispatch = useDispatch();
  const { staffs } = useSelector((state) => state.staffs);

  let data = staffs;
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,

    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = tableInstance;
  const handleExport = () => {};

  useEffect(() => {
    let id = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;
    dispatch(fetchStaffs(id));
  }, [dispatch]);

  return (
    <div>
      {staffs.length >= 1 ? (
        <div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">{translate("All Staff", "Wafanyakazi Wote")}</h4>

              <div>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={handleExport}
                >
                  {translate("Export", "Pakua")}
                </button>
              </div>
            </div>

            <div className="col-12">
              <table {...getTableProps()} className="table table-sm card-table">
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col"></div>

            <div className="col-auto">
              <div>
                <span className="table-stat">
                {translate("Showing", "Inaonyesha")} {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} {translate("to", "kwa")}{" "}
                  {pageIndex === 0
                    ? 10
                    : pageIndex * pageSize + pageSize > staffs.length
                    ? staffs.length
                    : pageIndex * pageSize + pageSize}{" "}
                  {translate("of", "ya")} {staffs.length} {translate("elements", "vipengele")}
                </span>
                <button
                  className="btn btn-round btn-sm btn-pagination"
                  onClick={() => previousPage()}
                >
                  <i className="fe fe-chevron-left"></i>
                </button>
                <span className="table-stat">{translate("Page", "Ukurasa")} {pageIndex + 1}</span>
                <button
                  className="btn btn-round btn-sm btn-pagination"
                  onClick={() => nextPage()}
                >
                  <i className="fe fe-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-empty">
          <div className="card card-fill">
            <div className="card-body text-center">
              <Empty />

              <h3 className="mt-4">{translate("No Staff", "Hakuna Wafanyakazi")}</h3>

              <p className="text-muted m-4">
              {translate("Please use the button below to create a new", "Tafadhali tumia kitufe kilicho hapa chini kuongeza mpya")} 
              </p>

              <Link
                to="/publishers/staff/create"
                className="btn btn-primary lift"
              >
                <span>
                  <i className="fe fe-plus"></i>
                </span>
                {translate("Create Staff", "Ongeza Mfanyakazi")}
                </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StaffTable;
