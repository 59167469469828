import React, { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { updatePublisherCoverApi } from "../../../../api/company";
import { fetchCompanyDetails } from "../../../../redux-store/Actions/company-action";
import { translate } from "../../../../components/LanguageTranslation/translate";

function UpdateCompanyCover(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { handleSubmit } = useForm();

  const [loadedFile, setLoadedFile] = useState({});
  const [fileInfo, setFileInfo] = useState({});

  const onDropCover = useCallback((uploadedFiles) => {
    setFileInfo({
      lastModified: uploadedFiles[0].lastModified,
      lastModifiedDate: uploadedFiles[0].lastModifiedDate,
      name: uploadedFiles[0].name,
      size: uploadedFiles[0].size,
      type: uploadedFiles[0].type,
    });

    readFiles(uploadedFiles, 5);
  }, []);


  function readFiles(files, type) {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reader aborted");

      reader.onerror = () => console.log("file reader failed");

      reader.onload = () => {
        const dataUrl = reader.result;

        switch (type) {
          case 5:
            setLoadedFile({
              url: dataUrl,
            });
            break;

          default:
            break;
        }
      };

      reader.readAsDataURL(file);
    });
  }

  const onSubmit = (data) => {
    setLoading(true);
    data.id = props.name.id;
    data.coverImage = loadedFile;

    updatePublisherCoverApi(props.name.id, data)
      .then((res) => {
        setLoading(false);
        dispatch(fetchCompanyDetails(props.name.id));
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={handleShow}>
        {translate("Change Image", "Badilisha Picha")}
      </button>

      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modal-90w"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col"></div>
            <div className="col-auto">
              <div className="close-btn">
                <button
                  type="button"
                  className="btn-close mr-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
            </div>
          </div>

          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <Dropzone onDrop={onDropCover} multiple={false} accept=".png">
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div className="mb-1">
                        <label className="form-label">{translate("Company Cover", "Jalada la Kampuni")}</label>
                      </div>
                      <div
                        {...getRootProps({
                          className: "dropzone dropzone-single mb-3",
                        })}
                      >
                        <input {...getInputProps()} />
                        <div className="upload-content p-4">
                          <i className="fe fe-upload-cloud"></i>
                          <p>{translate("BDrag and drop or click to upload", "Buruta na uangushe au ubofye ili upakie")}</p>
                          <p className="btn btn-sm btn-outline-primary">
                          {translate("Browse from your files", "Vinjari kutoka kwa faili zako")}
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>

                <aside className="file-preview">
                  {fileInfo && <div>{fileInfo.name}</div>}
                </aside>
              </div>
            </div>

            <div className="bottom-space">
              <div className="left">
                <button
                  type="reset"
                  className="btn btn-outline-danger btn-small text-black"
                  onClick={handleClose}
                >
                 {translate("Cancel", "Ghairi")} 
                </button>
              </div>
              <div className="col-auto">
                {loading ? (
                  <span className="btn-primary btn-small">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20}
                    />
                  </span>
                ) : (
                  <button className="btn btn-primary btn-small" type="submit">
                    {translate("Update Cover", "Sasisha Jalada")}  
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
}

export default UpdateCompanyCover;
