import React, { useEffect, useMemo } from "react";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchIssueDetails } from "../../../../redux-store/Actions/issue-action";
import { fetchCompanyDetails } from "../../../../redux-store/Actions/company-action";
import * as yup from "yup";
import { updateIssuesApi } from "../../../../api/publisher";
import COUNTRIES from "../../../../config/COUNTRIES.json";
import PUBLICATIONFREQUENCY from "../../../../config/PublicationFrequency.json";
import { translate } from "../../../../components/LanguageTranslation/translate";

const schema = yup
  .object({
    title: yup.string().required().min(3).max(255).label(""),
  })
  .required();

export const EditIssue = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const id = params.id;

  const { company } = useSelector((state) => state.company);
  const { issue, isLoading } = useSelector((state) => state.issue);

  const countries = localStorage.getItem("RIFALYCOUNTRIES")
    ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
    : [];
  const publicationFrequency = useMemo(() => PUBLICATIONFREQUENCY, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    let companyId = JSON.parse(
      localStorage.getItem("RIFALYPUBLISHER")
    ).companyId;
    dispatch(fetchIssueDetails(id));
    dispatch(fetchCompanyDetails(companyId));
  }, [id, dispatch]);

  function getCat(categoryId) {
    switch (categoryId) {
      case 1:
        return "NewsPaper";

      case 2:
        return "Magazine";

      case 3:
        return "Book";

      case 4:
        return "Article";

      default:
        return "NewsPaper";
    }
  }

  const onSubmit = (data, e) => {
    data.id = id;
    console.log(data);

    updateIssuesApi(data).then((res) => {
      console.log(res.data);
    });
  };

  if (!issue) {
    return (
      <section>
        <h2>{translate("Post not found!","Chapisho halijapatikana!")}!</h2>
      </section>
    );
  }

  if (issue === {}) {
    return (
      <section>
        <h2>{translate("Post not found!","Chapisho halijapatikana!")}</h2>
      </section>
    );
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                    {translate("Dashboard", "Dashibodi")}
                    </a>
                  </li>

                  <li
                    className="breadcrumb-item "
                    onClick={toPage("/publications")}
                  >
                    {translate("Publishers", "Wachapishaji")}
                  </li>
                  <li
                    className="breadcrumb-item "
                    onClick={toPage("/publications/issues")}
                  >
                     {translate("Issues", "Matoleo")}
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row mt-2 body-content">
              <div className="col-md-12">
                <div className="card">
                  <div className="px-4 pt-3">
                    <div className="">
                      <h6 className="header-pretitle">
                        {translate("Overview", "Muhtasari")}
                      </h6>

                      <h1 className="header-title">
                        {translate("Edit Issue", "Hariri Toleo")}
                      </h1>
                    </div>

                    <hr />
                  </div>

                  <div className="card-body">
                    {isLoading ? (
                      <div className="loader-holder d-flex justify-content-center align-items-center">
                        <Loader
                          type="BallTriangle"
                          heigth="100"
                          width="100"
                          color="green"
                          ariaLabel="loading-indicator"
                        />
                      </div>
                    ) : (
                      <div>
                        {issue && issue.publisher && (
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="p-3 mx-2"
                              >
                                <div className="row g-3 mb-3">
                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="company"
                                      className="form-label"
                                    >
                                      {translate("Publisher Company","Kampuni ya Wachapishaji")}
                                    </label>
                                    <select
                                      value={issue.publisherId}
                                      className="form-select"
                                      {...register("publisherId")}
                                    >
                                      <option disabled>{translate("Publisher","Mchapishaji")}</option>
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.companyName}{" "}
                                      </option>
                                    </select>

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="title"
                                      className="form-label"
                                    >
                                      {translate("Issue Title","Kichwa cha Toleo")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("title")}
                                      defaultValue={issue.title}
                                    />

                                    <p className="errors">
                                      {errors.title?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="category"
                                      className="form-label"
                                    >
                                      {translate("Category","Kundi")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("category")}
                                      defaultValue={getCat(issue.categoryId)}
                                    />

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="language"
                                      className="form-label"
                                    >
                                      {translate("Language","Lugha")}
                                    </label>
                                    <select
                                      {...register("language")}
                                      value={issue.language}
                                      className="form-select"
                                    >
                                      <option>{translate("Select Language","Chagua Lugha")}</option>
                                      <option disabled>
                                      {translate("Select Issue Language","Chagua Lugha ya Toleo")}
                                      </option>
                                      <option value="EN">{translate("English","Kiingereza")}</option>
                                      <option value="SW">{translate("Swahili","Kiswahili")}</option>
                                    </select>

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="category"
                                      className="form-label"
                                    >
                                      {translate("Price","Bei")}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("price")}
                                      defaultValue={issue.price}
                                    />

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-2">
                                    <label
                                      htmlFor="currency"
                                      className="form-label"
                                    >
                                      {translate("Currency","Sarafu")}
                                    </label>
                                    <select
                                      {...register("currency")}
                                      value={issue.currency}
                                      className="form-select"
                                    >
                                      {countries.map((c) => {
                                        return (
                                          <option
                                            key={c.currency}
                                            value={c.currency}
                                          >
                                            {c.currency}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="country"
                                      className="form-label"
                                    >
                                      {translate("Country","Nchi")}
                                    </label>
                                    <select
                                      {...register("country")}
                                      value={issue.country}
                                      className="form-select"
                                    >
                                      <option disabled>{translate("Select Country","Chagua Nchi")}</option>
                                      {countries.map((c) => {
                                        return (
                                          <option
                                            key={c.countryCode}
                                            value={c.countryCode}
                                          >
                                            {c.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="frequency"
                                      className="form-label"
                                    >
                                      {translate("Publication Frequency","Ratiba ya Uchapishaji")} *
                                    </label>
                                    <select
                                      value={issue.frequency}
                                      {...register("frequency")}
                                      className="form-control"
                                    >
                                      <option disabled>{translate("Select Frequency","Chagua Ratiba")}</option>
                                      {publicationFrequency.map((frequency) => (
                                        <option
                                          key={frequency.title}
                                          value={frequency.title}
                                        >
                                          {frequency.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="category"
                                      className="form-label"
                                    >
                                      {translate("Category","Kundi")}
                                    </label>
                                    <select
                                      className="form-select"
                                      {...register("publisherId")}
                                    >
                                      <option disabled>{translate("Select Category","Chagua Kundi")}</option>
                                    </select>

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="category"
                                      className="form-label"
                                    >
                                       {translate("Category","Kundi")}
                                    </label>
                                    <select
                                      className="form-select"
                                      {...register("publisherId")}
                                    >
                                      <option disabled>{translate("Select Category","Chagua Kundi")}</option>
                                    </select>

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>

                                  <div className="col-12 col-md-4 mb-1">
                                    <label
                                      htmlFor="category"
                                      className="form-label"
                                    >
                                      {translate("Category","Kundi")}
                                    </label>
                                    <select
                                      className="form-select"
                                      {...register("publisherId")}
                                    >
                                      <option disabled>{translate("Select Category","Chagua Kundi")}</option>
                                    </select>

                                    <p className="errors">
                                      {errors.company?.message}
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col"></div>
                                  <div className="col-auto">
                                    <button
                                      type="submin"
                                      className="btn btn-primary"
                                    >
                                      {translate("Submit","Wasilisha")}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};
