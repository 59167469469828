import React from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Home from "../containers/domains/dashboard/Index";
import IssueReport from "../containers/domains/dashboard/issueReport";
import IssueAnalysisReport from "../containers/domains/dashboard/issueAnalysisReport";
import Support from "../containers/Support";
import Validation from "../containers/Validation";
import Reports from "../containers/domains/reports/";
import RevenueReports from "../containers/domains/reports/revenue";
import DirectDownloadReports from "../containers/domains/reports/DirectDownloads";
import CorporateDownloadReports from "../containers/domains/reports/CorporateDownloads";
import SubscriptionDownloadReports from "../containers/domains/reports/SubscriptionDownloads";
import Issues from "../containers/domains/publishers/issue/Index";
import Publication from "../containers/domains/publishers/publication/Publication";
import Company from "../containers/domains/publishers/company/Company";
import Create from "../containers/domains/publishers/issue/Create";
import {Issue} from "../containers/domains/publishers/issue/Issue";
import CreateCompany from "../containers/domains/publishers/company/CreateCompany";
import CompanyDetails from "../containers/domains/publishers/company/CompanyDetails";
import CorporateSubscriptionTable from "../containers/domains/reports/CorporateSubscriptions/CorporateSubscriptionTable"
import NotFound from "../components/NotFound/Index";
import CreateStaff from "../containers/domains/publishers/staff/CreateStaff";
import Login from "../containers/Home/Login";
import CreatePublication from "../containers/domains/publishers/publication/CreatePublication";
import Publications from "../containers/domains/publishers/publication/Index";
import TestPub from "../containers/domains/publishers/publication/TestPub";
import EditPublication from "../containers/domains/publishers/publication/EditPublication";
import {EditIssue} from "../containers/domains/publishers/issue/EditIssue";
import UserDetails from "../containers/domains/users/UserDetails";
import Genres from "../containers/domains/settings/genres/Genres";
// import AdminDetails from "../containers/domains/users/admins/AdminDetails";
import Staffs from "../containers/domains/publishers/staff/Staff";
import Forgot from "../containers/Home/Forgot";
import Verify from "../containers/Home/Verify";
import NewPassword from "../containers/Home/NewPassword";
import Register from "../containers/Home/Register";
import Settings from "../containers/domains/settings/Settings";
import Notifications from "../containers/domains/settings/Notifications";
import Profile from "../containers/domains/profile/Profile";
import ProfileEdit from "../containers/domains/profile/ProfileEdit";

const routes = () => {
  const isAuthenticated = localStorage.getItem("RIFALYPUBLISHER");
  return (
    <BrowserRouter>
      <Routes>
        {/* {isAuthenticated ? (
          <> */}
        <Route
          path='/dashboard'
          element={<Home />}
        />
        <Route path="/dashboard/issues/show/:id" element={<IssueReport />}/>
        <Route path="/dashboard/issues/analysisreport/show/:id" element={<IssueAnalysisReport />}/>
        <Route
          path='/settings/genres'
          element={<Genres />}
        />
        <Route
          path='/settings/account'
          element={<Settings />}
        />
        <Route
          path='/settings/notifications'
          element={<Notifications />}
        />
        <Route
          path='/settings/account'
          element={<Settings />}
        />
        <Route
          path='/settings'
          element={<Settings />}
        />
        <Route
          exact
          path='/profile/edit'
          element={<ProfileEdit />}
        />
        <Route
          exact
          path='/profile'
          element={<Profile />}
        />

        {/* <Route path="/settings/genres" element={Genres/>} /> */}

        <Route
          path='/staffs/create'
          element={<CreateStaff />}></Route>
        <Route
          path='/staffs/:id'
          element={<UserDetails />}></Route>
        <Route
          path='/staffs'
          element={<Staffs />}></Route>

        <Route
          path='/publications/issues/test'
          element={<Issue />}></Route>
        <Route
          path='/publications/issues/edit/:id'
          element={<EditIssue />}></Route>
        <Route
          path='/publications/issues/create'
          element={<Create />}></Route>
        <Route
          path='/publications/issues/show/:id'
          element={<Issue />}></Route>
        <Route
          path='/publications/issues'
          element={<Issues />}></Route>

        <Route
          path='/publications/edit/:id'
          element={<EditPublication />}></Route>

        <Route
          path='/publications/create'
          element={<CreatePublication />}></Route>
        <Route
          path='/publications/:id'
          element={<Publication />}></Route>
        <Route
          path='/publications/papers'
          element={<Publications />}></Route>
        <Route
          exact
          path='/publications'
          element={<Publications />}
        />

        <Route
          path='/publishers/company/:id'
          element={<CompanyDetails />}></Route>

        <Route
          path='/reports/revenue'
          element={<Reports />}></Route>
        <Route
          path='/reports/directdownloads'
          element={<DirectDownloadReports />}></Route>
        <Route
          path='/reports/corporate-subscriptions'
          element={<CorporateSubscriptionTable />}></Route>
        <Route
          path='/reports/corporate-downloads'
          element={<CorporateDownloadReports />}></Route>
        <Route
          path='/reports/corporatedownloads/:companyID'
          element={<CorporateDownloadReports />}></Route>
        <Route
          path='/reports/subscriptiondownloads'
          element={<SubscriptionDownloadReports />}></Route>
        <Route
          path='/reports'
          element={<RevenueReports />}></Route>

        <Route
          path='/support'
          element={<Support />}></Route>
        <Route
          path='/validation'
          element={<Validation />}></Route>
        {/* </>
        ) : null} */}
        <Route
          path='/forgot-password'
          element={<Forgot />}></Route>
        <Route
          path='/verify-code'
          element={<Verify />}></Route>
        <Route
          path='/reset-password'
          element={<NewPassword />}></Route>
        <Route
          path='/register'
          element={<Register />}></Route>
        <Route
          path='/signout'
          element={<Login />}></Route>
        <Route
          path='/'
          element={<Login />}></Route>

        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default routes;
