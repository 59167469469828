import React from 'react';
import {DateTime} from 'luxon';
import { Link } from "react-router-dom";
import { translate } from "../../../components/LanguageTranslation/translate";
import { thousandSeparator } from "./common";

export const ISSUEREPORTCOLUMNS = [
  {
    id: 0,
    header: 'S/NO',
    accessor: 'id',
    Cell: props => <div>{(props.row.index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}</div>
  },
  {
    id: 1,
    header: 'Publication',
    accessor: 'title',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 2,
    header: 'Issue No',
    accessor: 'issueNo',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 3,
    header: 'Date',
    accessor: 'releaseDate',
    Cell: props => <div className="text-capitalize">{(new Date(props.value)).toLocaleDateString()}</div>
  },
  {
    id: 4,
    header: 'Impression',
    accessor: 'impressions',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 5,
    header: 'Reads',
    accessor: 'reads',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 6,
    header: 'Currency',
    accessor: 'currency',
    Cell: props => <div className="text-capitalize">{props.value}</div>
  },
  {
    id: 7,
    header: 'Sales',
    accessor: 'sales',
    Cell: (props) => <div>{props.value > 0 ? thousandSeparator((parseFloat(props.value)).toFixed(0)) : 0}</div>,
  },
  {
    header: "Actions",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Link to={`/dashboard/issues/analysisreport/show/${props.row.original.publicationId}`} state={{ issueAnalysis: props.row.original }}>
          <button className="btn btn-primary" >
            {translate("View", "Tazama")}
          </button>
        </Link>
      </div>
    ),
  },

]
