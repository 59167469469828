import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { OVERVIEWISSUECOLUMNS } from "./OverviewIssueColumns";
import EmptyData from "../../../components/EmptyData/Index";
import { translate } from "../../../components/LanguageTranslation/translate";
import {thousandSeparator} from "./common";
import Loader from "react-loader-spinner";

function OverviewIssuesTable({ issueData}) {
  const columns = useMemo(() => OVERVIEWISSUECOLUMNS, []);
  const data = issueData;
  const [ loading, setLoading ] = useState(false);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0},
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex, pageSize, globalFilter},
  } = tableInstance;

  
  return (
    <div>
     
     <div className="row mt-2 body-content">
        {loading ? (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>{translate("Loading data...","Inapakia Taarifa...")}</p>
          </div>
        ) : data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                    {translate("Issues:","Matoleo:")} {" "}
                      {data.length > 0
                        ? `${data[ 0 ].currency} ${thousandSeparator(
                          data
                            .reduce((t, a) => {
                              t += a.amount;
                              return t;
                            }, 0)
                            .toFixed(0)
                        )}`
                        : 0}
                    </h4>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                  {translate("Showing", "Inaonyesha")} {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} {translate("to", "kwa")}{" "}
                    {pageIndex > 10
                      ? data.length
                      : pageIndex * pageSize + pageSize > data.length
                        ? data.length
                        : pageIndex * pageSize + pageSize}{" "}
                    {translate("of", "ya")} {data.length} {translate("elements", "vipengele")}
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">{translate("Page", "Ukurasa")} {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Issues"  />
        )}
      </div>
    </div>
  );
}

export default OverviewIssuesTable;