import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { translate } from "../../../../components/LanguageTranslation/translate";

function Genres() {
  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/publishers/")}>
                      {translate("Dashboard", "Dashibodi")}
                    </a>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    {translate("Settings", "Mipangilio")}
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">
                  {translate("Overview", "Muhtasari")}
                </h6>

                <h1 className="header-title">
                  {translate("Issues", "Matoleo")}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Genres;
