import axios from "axios";
import apiConfig from "./api_config";

const baseURL = apiConfig.PUBLISHER_URL;

// function getHeaders() {
//   const user = localStorage.getItem("RIFALYADMIN");
//   const { token } = user ? JSON.parse(user) : {};
//   return {
//     Authorization: `Bearer ${token}`,
//   };
// }

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchCompanyUpdateApi=(data)=>{
  return api({
    url: `companies`,
    method:"PATCH",
    data:data
  })
}
export const fetchPublisherApi = ()=>{
  return api({
    url: `companies`,
    method: "GET",
  });
}

export const fetchPublisherByIdApi = (id)=>{
  return api({
    url: `companies/${id}`,
    method: "GET",
  });
}

export const updatePublisherBillingApi = (data)=>{
  return api({
    url: `companies/billing`,
    data: data,
    method: "POST",
  });
}
export const createPublisherApi = (data)=>{
  return api({
    url: `companies`,
    data: data,
    method: "POST",
  });
}

export const deletePublisherApi = (id)=>{
  return api({
    url: `companies/`,
    data: id,
    method: "DELETE",
  });
}


export const updateCompanyStatusApi = (id, data)=>{
  return api({
    url: `companies/${id}`,
    data: data,
    method: "PATCH",
  });
}

export const updatePublisherCoverApi = (id, data)=>{
  return api({
    url: `companies/update-cover/${id}`,
    data: data,
    method: "PATCH",
  });
}


export const uploadPublisherDocsApi = (data)=>{
  return api({
    url: `companies/upload-docs`,
    data: data,
    method: "POST",
  });
}





