import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteIssue from "./DeleteIssue";
import UpdateIssue from "./UpdateIssue";
import { translate } from "../../../../components/LanguageTranslation/translate";

export const ISSUECOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    header: "Issue title",
    accessor: "title",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "category",
    accessor: "categoryId",
    Cell: (props) => (
      <div className="capitalize">{checkCategory(props.value)}</div>
    ),
  },
  {
    header: "price",
    accessor: "price",
    Cell: (props) => <div>{new Intl.NumberFormat().format(props.value)}</div>,
  },
  {
    header: "CURRENCY",
    accessor: "currency",
    Cell: (props) => <div className="uppercase">{props.value}</div>,
  },
  {
    header: "country",
    accessor: "country",
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={`/publications/issues/show/${props.row.original.id}`}
              className="dropdown-item"
            >
              {translate("View", "Tazama")}
            </Link>

            {/* <Link to={`/publications/issues/edit/${props.row.original.id}`} className="dropdown-item">
            Edit
          </Link>

          <div className={ (props.row.original.status) === "ACTIVE" ? "dropdown-item disabled hidden" : "dropdown-item"}>
            <UpdateIssue name={props.row.original}></UpdateIssue>
          </div> 


          <div className={ (props.row.original.status) !== "ACTIVE" ? "dropdown-item disabled hidden" : "dropdown-item" }>
            <UpdateIssue name={props.row.original}></UpdateIssue>
          </div>

          <div className='dropdown-item text-danger'>
              <DeleteIssue name={props.row.original}></DeleteIssue>
          </div> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Inactive
        </div>
      );
  }
}

function checkCategory(categoryId) {
  switch (categoryId) {
    case 1:
      return <div className="text-capitalize">NewsPaper</div>;

    case 2:
      return <div className="text-capitalize">Magazine</div>;

    default:
      return <div className="text-capitalize">Book</div>;
  }
}
