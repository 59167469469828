import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EmptyData from "../../../../components/EmptyData/Index";
import { AnimatePresence, motion } from "framer-motion";
import PublicationFilter from "./PublicationFilter";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import PaginationControl from "../../../../components/PaginationControl";
import { translate } from "../../../../components/LanguageTranslation/translate";

function PublicationTable(props) {
  const user = JSON.parse(localStorage.getItem("RIFALYPUBLISHER"));
  const canUpload = parseInt(user.role) === 2 || parseInt(user.role) === 6;
  const [filter, setFilter] = useState("");
  const columns = props.columns;
  const raw = props.raw;
  const onPageChange = props.onPageChange;
  const path = props.path;
  const [currentPage, setCurrentPage] = useState(1);

  const publications = props.data;

  var data = publications;

  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    data = data.map((d) => {
      let r = d;
      delete r.document;
      delete r.cover;
      delete r.publisher;
      delete r.issue;
      delete r.publication_stories;
      r.category = d.categoryId == 1 ? "Newspaper" : "Magazine";
      delete r.categoryId;
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `My Rifaly Publications ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  const nextPage = () => {
    const next = currentPage + 1;
    onPageChange(next);
    setCurrentPage(next);
  };

  const previousPage = () => {
    const prev = currentPage - 1;
    onPageChange(prev);
    setCurrentPage(prev);
  };

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col py-2">
          <PublicationFilter
            filter={filter}
            onChange={(value) => {
              setFilter(value);
            }}
          />
        </div>
        {canUpload ? (
          <div className="col-auto py-2">
            <Link to="/publications/create" className="btn btn-primary lift">
              <span>
                <i className="fe fe-plus"></i>
              </span>
              {translate("Upload", "chapisha")}
            </Link>
          </div>
        ) : null}
      </div>

      <div className="row mt-2 body-content">
        {publications && publications.length >= 1 ? (
          <div className="col-md-12">
            {/* the table here */}

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">{translate("Uploaded Publications", "Machapisho yaliyopandishwa")}</h4>

                    <div>
                      <button
                        onClick={handleExport}
                        className="btn btn-outline-primary btn-sm"
                      >
                        {translate("Export", "Pakua")}
                      </button>
                    </div>
                  </div>

                  <table className="table table-sm card-table">
                    <thead>
                      {tableInstance.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      <AnimatePresence>
                        {tableInstance.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                  {translate("Showing", "Inaonyesha")} {raw?.pageSize ? raw?.pageSize : 20} {translate("of", "ya")}{" "}
                    {raw?.totalItems} {translate("elements", "vipengele")}
                  </span>
                  {raw?.hasPrevious ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={previousPage}
                    >
                      <i className="fe fe-chevron-left"></i>
                    </button>
                  ) : null}
                  <span className="table-stat">
                  {translate("Page", "Ukurasa")}{" "}
                    {raw?.pages ? raw?.pages?.currentPage : raw?.currentPage}
                  </span>
                  {raw?.hasNext ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={nextPage}
                    >
                      <i className="fe fe-chevron-right"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Publications" path="/publications/create" />
        )}
      </div>
    </div>
  );
}

export default PublicationTable;
