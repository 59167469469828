import React from "react";
import TzFlag from "../../assets/img/TzFlag.png";
import EnFlag from "../../assets/img/EnFlag.png";

export default function Translate({ engText, swhText }) {
  const currentLanguage = localStorage.getItem("selectedLanguage") || "en";

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "sw" : "en";
    localStorage.setItem("selectedLanguage", newLanguage);
    window.location.reload();
  };

  return (
    <div onClick={toggleLanguage} className="d-flex align-items-center">
      {currentLanguage === "en" ? (
        <div className="d-flex align-items-center">
          <img
            src={TzFlag}
            alt="Swahili-Flag"
            style={{ width: "25px", height: "20px" }}
          />
          <span className="ms-2">SW</span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <img
            src={EnFlag}
            alt="English-Flag"
            style={{ width: "25px", height: "20px" }}
          />
          <span className="ms-2">EN</span>
        </div>
      )}
    </div>
  );
}
