import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { ReactComponent as ShareIcon } from "../../../../assets/img/share.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/img/mail.svg";
import { ReactComponent as TelegramIcon } from "../../../../assets/img/telegram.svg";
import { ReactComponent as WhatsappIcon } from "../../../../assets/img/whatsapp.svg";
import { ReactComponent as XIcon } from "../../../../assets/img/xlink.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/img/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/img/linkedin.svg";
import { ReactComponent as Copylink } from "../../../../assets/img/Copylink.svg";
import { translate } from "../../../../components/LanguageTranslation/translate";
import axios from "axios";

function SharePopup(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const inputRef = useRef(null);
  const copyLinkRef = useRef(null);
  const [source, setSource] = useState("rifaly");
  const linkdata = props.data ? props.data : {};

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.value = `${shareTracker}&source=rifaly&link=${shareUrl}`;
      inputRef.current.select();
      document.execCommand("copy");
    }
    if (copyLinkRef.current) {
      copyLinkRef.current.innerHTML = "Link copied";
      copyLinkRef.current.style.color = "#00b17a";
      copyLinkRef.current.style.textDecoration = "underline";
    }
  };

  const title = "Rifaly";
  const description = "Rifaly, Discover What matters";
  const newscover = linkdata.cover_link;
  const newspaperId = linkdata.id;
  const issueTitle = linkdata.issue.title;
  const shareUrl = `https://www.rifaly.com/newspaper/${newspaperId}/${issueTitle.replace(
    /\s+/g,
    ""
  )}`;
  const shareTracker=`https://api.rifaly.co.tz/api/emails/capture-link-share?owner=${linkdata.publisherId}&channel=web`;

  return (
    <div>
      <Helmet
        title={title}
        meta={[
          { property: "og:title", content: { issueTitle } },
          { property: "og:description", content: { description } },
          { property: "og:image", content: { newscover } },
          { property: "og:url", content: shareUrl },
        ]}
      />
      <div className="me-2 text-success" onClick={handleShow}>
        <ShareIcon />
      </div>

      <Modal show={show} onHide={handleClose} {...props}>
        <Modal.Header closeButton>
          <Modal.Title>{translate("Share with","Sambaza na")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="editForm">
            <div className="row share-buttons">
              <div className="col-md-2">
                <div className="d-flex flex-column align-items-center">
                  <WhatsappShareButton
                    url={`${shareTracker}&source=whatsapp&link=${shareUrl}`}
                    title={issueTitle}
                  >
                    <WhatsappIcon className="shareIcon" size={32} round />
                  </WhatsappShareButton>
                  <label htmlFor="whatsapp" className="form-label">
                    Whatsapp
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex flex-column align-items-center">
                  <TwitterShareButton
                    url={`${shareTracker}&source=twitter&link=${shareUrl}`}
                    title={issueTitle}
                  >
                    <XIcon className="shareIcon" size={32} round />
                  </TwitterShareButton>
                  <label htmlFor="x" className="form-label">
                    X
                  </label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-flex flex-column align-items-center">
                  <TelegramShareButton
                    url={`${shareTracker}&source=telegram&link=${shareUrl}`}
                    title={issueTitle}
                  >
                    <TelegramIcon className="shareIcon" size={32} round />
                  </TelegramShareButton>
                  <label htmlFor="telegram" className="form-label">
                    Telegram
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex flex-column align-items-center">
                  <EmailShareButton
                    url={`${shareTracker}&source=email&link=${shareUrl}`}
                    subject={issueTitle}
                    body={description}
                  >
                    <EmailIcon className="shareIcon" size={32} round />
                  </EmailShareButton>
                  <label htmlFor="gmail" className="form-label">
                    Gmail
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex flex-column align-items-center">
                  <FacebookShareButton
                    url={`${shareTracker}&source=facebook&link=${shareUrl}`}
                    quote={description}
                  >
                    <FacebookIcon className="shareIcon" size={32} round />
                  </FacebookShareButton>
                  <FacebookShareCount url={`${shareTracker}&source=facebook&link=${shareUrl}`}>
                    {(shareCount) => (
                      <span className="myShareCountWrapper">{shareCount}</span>
                    )}
                  </FacebookShareCount>
                  <label htmlFor="Facebook" className="form-label">
                    Facebook
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="d-flex flex-column align-items-center">
                  <LinkedinShareButton
                    url={`${shareTracker}&source=linkedin&link=${shareUrl}`}
                    title={issueTitle}
                    summary={description}
                  >
                    <LinkedinIcon className="shareIcon" size={32} round />
                  </LinkedinShareButton>
                  <label htmlFor="linkedIn" className="form-label">
                    LinkedIn
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-center">
                  <label
                    htmlFor="sharelink"
                    className="form-label d-flex justify-content-center"
                  >
                    {translate("Or share with link", "Au sambaza")}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control with-icon"
                      id="sharelink"
                      placeholder="https://www.figma.com/file/NlfVhYygR9mAQasassdsada/Share..."
                      defaultValue={`${shareTracker}&source=rifaly&link=${shareUrl}`}
                      ref={inputRef}
                    />
                    <span
                      className="share-input-icon"
                      onClick={copyToClipboard}
                    >
                      <Copylink />
                    </span>
                  </div>
                  <div
                    ref={copyLinkRef}
                    id="copyLink"
                    className="copy-link"
                  ></div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SharePopup;
